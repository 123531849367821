<template>
  <div
    v-if="itemGroup"
    class="MemorizeView">
    <ProgressBar :progress="progress" />

    <!-- <div> group: {{ itemGroup.label }}, {{ localItemIndex + 1}}/{{ itemGroup.itemIds.length }} -- {{ module.getters('learnProgress') }}</div> -->

    <div class="itemContainer">
      <transition :name="transitionName">
        <component
          :is="config.MemorizeItem"
          :key="currentItemId"
          class="Item"
          :item-id="currentItemId" />
      </transition>
    </div>
    <div class="header">
      <div
        class="exitButton --global-clickable"
        @click="$router.push('../learn')">
        Exit
      </div>
    </div>
    <div class="footer">
      <!-- <div>Learn status: {{ itemLearnStatus }} | <span @click="toggeLearnStatus()">toggle</span></div> -->
      <NavigationWithArrows
        :length="itemGroup.itemIds.length"
        :index="localItemIndex"
        @click-prev="prev()"
        @click-next="next()">
        <div
          class="startQuizButton --global-clickable"
          @click="startQuiz()">
          {{ $t('startQuiz') }}
        </div>
      </NavigationWithArrows>
    </div>
  </div>
</template>

<script>
import loadImage from 'image-promise';
import ProgressBar from '@/modules/games-shared/components/ProgressBar';
import NavigationWithArrows from '@/modules/games-shared/components/NavigationWithArrows';

const translations = {
    startQuiz: 'Start quiz',
    startQuiz_no: 'Start quiz'
};

export default {
    translations,
    components: { NavigationWithArrows, ProgressBar },
    debugComponent: 'systems-shared_MemorizeViewDebug',
    inject: ['config', 'module'],
    props: {
        itemId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            itemGroup: null,
            direction: 1,
            loaded: false
        };
    },
    computed: {
        currentItemId() {
            return this.itemId;
        },
        localItemIndex() {
            return this.itemGroup.itemIds.indexOf(this.currentItemId);
        },
        itemLearnStatus() {
            // return this.$store.state.systemAlphabet.testValue
            // return this.module.state.testValue
            return (this.module.state.items[this.currentItemId] || {}).learned || 0;
        },
        progress() {
            return (this.localItemIndex + 1) / this.itemGroup.itemIds.length;
        },
        transitionName() {
            return this.direction > 0 ? 'out-left' : 'out-right';
        }
    },
    created() {
        this.itemGroup = this.config.itemGroups.find(group => group.itemIds.indexOf(this.currentItemId) !== -1);
        if (!this.itemGroup) {
            this.$router.push('../learn');
        }
    },
    mounted() {
        loadImage(this.config.getImagesInGroup(this.itemGroup))
            .then(() => {
                this.loaded = true;
            })
            .catch(function (err) {
                console.error('One or more images have failed to load :('); // eslint-disable-line no-console
                console.error(err.errored); // eslint-disable-line no-console
                console.info('But these loaded fine:'); // eslint-disable-line no-console
                console.info(err.loaded); // eslint-disable-line no-console
            });
    },
    methods: {
        toggeLearnStatus() {
            // this.$store.dispatch('systemAlphabet/toggleLearnStatus', { itemId: this.currentItemId })
            this.module.dispatch('toggleLearnStatus', { itemId: this.currentItemId });
        },
        prev() {
            if (this.localItemIndex > 0) {
                this.$router.push(`${this.itemGroup.itemIds[this.localItemIndex - 1]}`);
                this.direction = -1;
            }
        },
        next() {
            if (this.localItemIndex < this.itemGroup.itemIds.length - 1) {
                this.$router.push(`${this.itemGroup.itemIds[this.localItemIndex + 1]}`);
                this.direction = 1;
            }
        },
        startQuiz() {
            this.$router.push(`../quiz/${this.itemGroup.index}`);
        }
    }
};
</script>

<style scoped lang="scss">
.MemorizeView {
    position: relative;
    display: flex;
    flex-direction: column;
}
.header {
    display: flex;
    justify-content: flex-end;
    height: 3em;
    align-items: center;
    padding-right: 2em;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
.exitButton {
    margin-left: 2em;
    color: rgba(white, 0.5);
}
.itemContainer {
    position: relative;
    flex: 1;
    overflow-y: auto;
}
.Item {
    // height: 100%;

    $transitionDuration: 0.5s;
    flex: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.out-left-enter-active,
    &.out-left-leave-active,
    &.out-right-enter-active,
    &.out-right-leave-active {
        transition: opacity $transitionDuration, transform $transitionDuration;
    }

    &.out-left-enter {
        // opacity: 0;
        // transform: scale(0.2);
        transform: translate3d(100%, 0, 0); // scale(0.5);
    }
    &.out-right-enter {
        // opacity: 0;
        // transform: scale(0.2);
        transform: translate3d(-100%, 0, 0); // scale(0.5);
    }

    &.out-left-leave-to {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }
    &.out-right-leave-to {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }
}
.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    // background-color: white;
    // color: black;
    // border-top: 1px solid black;
    padding: 1em 2em;
    padding-bottom: 1em;
}
.startQuizButton {
    background-color: white;
    color: black;
    padding: 0.5em 1em;
    border-radius: 0.2em;
}
</style>
